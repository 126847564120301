<template>
  <component
    class="project-unit-summary"
    :class="{
      compact: compact,
      'py-2': !isCollapsible || (expanded && isCollapsible)
    }"
    :is="mainWrapper"
    v-model="expanded"
  >
    <template #title>
      <div class="d-flex justify-content-between px-2 mb-1">
        <!-- plot -->
        <div class="plot mr-2">
          {{ unit.plot }}
        </div>
        <div class="d-flex align-items-center">
          <!-- status -->
          <UnitStatus :status="unit.status"></UnitStatus>
          <!-- #slot -->
          <slot name="top-right"></slot>
        </div>
      </div>
    </template>

    <div v-if="!isCollapsible" class="d-flex justify-content-between px-2 mb-1">
      <!-- plot -->
      <div class="plot mr-2">
        {{ unit.plot }}
      </div>
      <div class="d-flex align-items-center">
        <!-- status -->
        <UnitStatus :status="unit.status"></UnitStatus>
        <!-- #slot -->
        <slot name="top-right"></slot>
      </div>
    </div>

    <div class="row px-2">
      <div class="main-info">
        <!-- street & floorplan -->
        <p v-if="unit.street">{{ unit.street }}</p>
        <p v-if="unit.projectUnitFloorPlan">
          {{ unit.projectUnitFloorPlan.name }}
        </p>

        <!-- phase -->
        <chip v-if="unit.phase" :class="compact ? 'mt-1' : 'mt-2'">
          {{ unit.phase.name }}
        </chip>
      </div>

      <div class="additional-info" :class="compact ? 'mt-1' : 'mt-2'">
        <!-- bedroom -->
        <div v-if="unit.projectUnitFloorPlan.numberOfBedroom" class="mr-2">
          <i class="fas fa-bed"></i>
          {{ unit.projectUnitFloorPlan.numberOfBedroom }}
        </div>
        <!-- bathroom -->
        <div v-if="unit.projectUnitFloorPlan.numberOfBathroom" class="mr-2">
          <i class="fas fa-bath"></i>
          {{ unit.projectUnitFloorPlan.numberOfBathroom }}
        </div>
        <!-- orientation -->
        <div v-if="unit.orientation" class="mr-2">
          <i class="fas fa-compass"></i>
          {{ getOrientationShorthand(unit.orientation) }}
        </div>
        <!-- built-up -->
        <div v-if="unit.projectUnitFloorPlan.builtUpArea" class="mr-2">
          <i class="fas fa-table-cells-large"></i>
          {{
            numberFormat(unit.projectUnitFloorPlan.builtUpArea, {
              decimals: 0
            })
          }}
          {{ getAreaUnitTypeAbbr(unit.projectUnitFloorPlan.areaUnitType) }}
        </div>
      </div>
    </div>

    <div class="price-and-slot px-2" :class="compact ? 'mt-1' : 'mt-2'">
      <!-- sale price -->
      <div v-if="unit.salePrice" class="sale-price mr-1">
        <span>RM {{ numberWithCommas(parseInt(unit.salePrice)) }}</span>
      </div>
      <!-- #slot -->
      <slot name="bottom"></slot>
    </div>
  </component>
</template>

<script>
import { getOrientationShorthand } from "@/enums/project/orientation";
import { getAreaUnitTypeAbbr } from "@/enums/areaUnitTypeAbbr";
import { numberFormat, numberWithCommas } from "@/utils/string";
import Collapsible from "@/components/GlobalComponents/Collapsible";

export default {
  components: {
    Chip: () => import("@/components/GlobalComponents/Chip"),
    UnitStatus: () => import("@/modules/Project/components/Unit/UnitStatus")
  },
  mixins: [],
  props: {
    unit: {
      type: Object,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    },
    isCollapsible: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      expanded: false
    };
  },
  computed: {
    mainWrapper() {
      return this.isCollapsible ? Collapsible : "div";
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getOrientationShorthand,
    getAreaUnitTypeAbbr,
    numberFormat,
    numberWithCommas
  }
};
</script>

<style lang="scss">
.project-unit-summary {
  .additional-info {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    color: #454545;
    i {
      margin-right: 4px;
    }
  }

  .plot {
    font-size: 20px;
    font-weight: bold;
  }
  .sale-price {
    font-size: 20px;
    font-weight: bold;
  }

  // Compact design
  &.compact {
    p {
      font-size: 10px;
    }
    .main-info {
      width: 50%;
      padding: 4px;
    }
    .additional-info {
      width: 50%;
      padding: 4px;
      grid-template-columns: repeat(2, auto);
      grid-gap: 0px;
      font-size: 12px;
    }
    .sale-price {
      font-size: 16px;
    }
  }
}
</style>
